'use strict';
import {notifyPersistent} from "./events/notify";
import {registerGa} from "./events/analytics";
import {fetchPlaylist} from "./utils/queries";
import {getDownloadUrls} from "./utils/convert";
import initialLoader from "./widgets/initialLoader";
import {miniButton} from "./widgets/button"
import {MuteBtn, HomeBtn, OpenBtn, LoopBtn, PrevBtn, PlayBtn, NextBtn, FullScreenBtn, ShareBtn, Spacer} from "./widgets"
import {pixelSpinner} from "./widgets/spinners";

import VideoPlayer from "./player/video";

import play from "./player/play";

import {requestFullScreen,isFullscreen} from "./utils/fullscreen";
import isMobile from "./utils/isMobile";
import init from "./main";


function transformObject(o,t){
  return {
    transformOrigin: o,
    WebkitTransformOrigin : o,
    transform : t,
    WebkitTransform : t
  }
}


(async function(){
  'use strict';
  var app = await init();
  //First retrieve all elements from page
  var text = document.querySelector("#begin-text");
  var spinner = document.querySelector("#begin-text .btn");
  var ve = document.getElementById("video-player");
  var resizeController = new VideoPlayer(ve);
  var controlBar = document.querySelector("#video-controls");
  var playlistElement = document.getElementById("playlist");
  var title = document.querySelector("#video-title");
  var loader = pixelSpinner({hidden: true});

  ve.parentNode.insertBefore(loader, ve);
  //Now we can set up the resize event
  function onResize(){
    const width  = window.innerWidth  || 0;
    const height = window.innerHeight || 0;
    resizeController.resize(width, height);
    if (width < height){
      /* *** Portrait mode *** */
      Object.assign(controlBar.style,{
        bottom:"6px",
        right:0,
        left:0,
        width:"auto",
        top:"auto",
      },transformObject("50% 100% 0",null));
    }else{
      /* *** Landscape mode *** */
      Object.assign(controlBar.style,{
        right : "auto",
        top   : "auto",
        bottom: "auto",
        width: height+"px",
        left  : "auto"
      },transformObject(
        "0% 0% 0",
        "translateX("+(width-48)+"px) rotate(-90deg) "
      ))
    }
  }
  //We also declare a play() function for later use
  function doPlay(item){
    try{
      play(ve, item);
      title.textContent = item.id
    }catch(e){
      console.warn("play() failed : ", e);
      title.textContent = "Failed to play"+ item.id;
    }
  }
  //We resize as early as possible
  onResize();

  window.addEventListener("resize", onResize);
  window.addEventListener("orientationchange", onResize);
  //"canplay" listener is only to support exotic video formats.
  // The player is resized each time a new video is loaded.
  ve.addEventListener("canplay", onResize);
  ve.addEventListener("loadedmetadata", onResize);
  //Samsung internet provides absolutely no good way to know when videoWidth and videoHeight will be available...
  if(/SamsungBrowser/.test(navigator.userAgent)) {
    setInterval(onResize,2000);
  }

  //Ugly wiring between video loading display and <video> element
  ve.addEventListener("progress",(e)=>{
    try{
      var loadedPercentage = ve.buffered.end(0) / ve.duration;
      //FIXME use a definite loader to show progress
      loader.progress = loadedPercentage*100;
    }catch(e){
      //Happen when content is not chunked.
      //console.log(e);
      loader.progress = 80;
    }

  });

  ve.addEventListener("canplaythrough",(e)=>{
    //console.log("video can play");
    onResize();
    loader.classList.add("hidden");
  })
  ve.addEventListener("waiting",(e)=>{
    //console.log("video is loading");
    loader.classList.remove("hidden");
  })
  ve.addEventListener("error",console.warn.bind(console,"<video> Error : "));

  let isReady = initialLoader(spinner);
  //wait for playlist
  let playlist = await fetchPlaylist();
  let currentIndex = 0;
  //console.log("got playlist : ", list);

  if(!Array.isArray(playlist) || playlist.length == 0){
    notifyPersistent("warn", "playlist is empty. Nothing to do");
    return;
  } //Stop there on empty playlist

  //Set up controls
  //FIXME make an initial set-up before receiving playlist and modify only if more than 1 element
  let controls;
  if(playlist.length == 1) {
    ve.loop = true;
    controls = [
      new MuteBtn(ve),
      new Spacer(),
      new PlayBtn(ve),
      new Spacer(),
      new FullScreenBtn(ve)
    ]
  }else{
    controls = [
      new MuteBtn(ve),
      new Spacer(),
      new PrevBtn(function(){
         currentIndex-= 1;
         if(currentIndex < 0) currentIndex = playlist.length -1;
         doPlay(playlist[currentIndex]);
      }),
      new PlayBtn(ve),
      new NextBtn(function(){
         currentIndex+= 1;
         if(playlist.length <= currentIndex) currentIndex = 0;
         doPlay(playlist[currentIndex]);
      }),
      new Spacer(),
      new LoopBtn(ve),
      new FullScreenBtn(ve)
    ]
  }
  controls.forEach(function(item){
    controlBar.appendChild(item.render());
  });
  //Set up onend event for non-loop
  ve.addEventListener("ended",()=>{
    currentIndex+= 1;
    if(playlist.length <= currentIndex) currentIndex = 0;
    doPlay(playlist[currentIndex]);
  },false)
  isReady().then(function(){
    //This is executed only after user clicked on the button
    //wait for a user interaction
    //Only feasible in the context of a user interaction
    //Not sure how it's interpreted in async/await
    registerGa()
    if(isMobile()){
      requestFullScreen();
    }
    const pre_source = ve.dataset.project;
    if(!pre_source){ //FIXME a better way to check if it has a source?
      const item = playlist[currentIndex]
      text.classList.add("hidden");
      doPlay(item);
    }else{
      const data = JSON.parse(pre_source);
      getDownloadUrls(data)
      .then(doPlay)
      .then(_ => text.classList.add("hidden"))

    }
  });
})();
