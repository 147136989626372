'use strict';

export default class Center{
  constructor(){
    this._el = document.createElement("DIV");
    this._el.className = "center-point"
    document.body.appendChild(this._el);
  }
  portrait(){

  }
}
