'use strict';
import {isFullscreen, requestFullScreen, cancelFullScreen} from "../utils/fullscreen";
import {setMute,getMute} from "../events/playerState";
/* DEPRECATED use makeElement and it's subfunctions */
/* see burger.js for examples */
export class Spacer {
  constructor(){
    this.el = document.createElement("SPAN");
    this.el.className = "spacer";
  }
  onPlaylistStateChange(){}
  render(){
    return this.el;
  }
}
// @deprecated should use button() from utils/makeElement.js
class Button {
  constructor(id, classes){
    this.btn = document.createElement("BUTTON");
    this.btn.type = "button";
    this.btn.className="control";
    this.btn.id = id;
    if (Array.isArray(classes) && 0 < classes.length){
      classes.forEach(function(c){
        btn.classList.add(c);
      })
    }
  }
  useIcon(name,color){
    color = color||"#fff";
    return `<svg style='fill:${color};' height="24" viewBox="0 0 24 24" width="24">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-${name}"></use>
        </svg>`
  }
  hide(){
    this.btn.classList.add("hidden");
  }
  show(){
    this.btn.classList.remove("hidden");
  }
  //get called when playlist state has changed with new state.
  render(){
    return this.btn;
  }
}

//A Default button that displays only when 1 < playlist.length
class ButtonMv extends Button{
  constructor(id,classes){super(id,classes)}
  onPlaylistStateChange(list){
    if(list.length < 2){
      this.hide();
    }else{
      this.show();
    }
  }
}
export class PlayBtn extends Button{
  constructor(player){
    super("play-pause");
    this.player = player;
    this.isPlaying = false;
    this.btn.innerHTML = this.useIcon("play");
    this.btn.onclick = this.handleClick.bind(this);
    //make sure we stay synced
    this.player.addEventListener("play",()=>{
      this.btn.innerHTML = this.useIcon("pause");
      this.isPlaying = true;
    });
    this.player.addEventListener("pause",()=>{
      this.btn.innerHTML = this.useIcon("play");
      this.isPlaying = false;
    });
  }
  handleClick(){
    if(this.isPlaying === true){
      this.player.pause();
    }else{
      this.player.play();
    }
  }
}
export class LoopBtn extends ButtonMv{
  constructor(player){
    super("loop");
    this.player = player;
    this.player.loop = true;
    this.btn.innerHTML = this.useIcon("loop","#fff");
    this.btn.onclick = this.handleClick.bind(this);
  }
  handleClick(){
    this.player.loop = !this.player.loop;
    console.log("loop:",this.player.loop);
    if(this.player.loop === true){
      this.btn.innerHTML = this.useIcon("loop","#fff");
    }else{
      this.btn.innerHTML = this.useIcon("loop","#333");
    }
  }
}



export class NextBtn extends ButtonMv{
  constructor(handler){
    super("next");
    this.btn.innerHTML = this.useIcon("next");
    this.btn.onclick = handler;
  }
}
export class PrevBtn extends ButtonMv{
  constructor(handler){
    super("prev");
    this.btn.innerHTML = this.useIcon("prev");
    this.btn.onclick = handler;
  }
}


export class FullScreenBtn extends Button{
  constructor(player){
    super("fs","pull-right");
    this.player = player;
    this.btn.innerHTML = this.useIcon("fullscreen");
    this.btn.onclick = this.handleClick.bind(this);
  }
  update() {
    if (!isFullscreen()){
      this.btn.innerHTML = this.useIcon("fullscreen");
    }else{
      this.btn.innerHTML = this.useIcon("fullscreen-exit");
    }
  }
  handleClick(){
    console.log("isFullscreen :",isFullscreen())
    if (isFullscreen()){
      cancelFullScreen();
    }else{
      console.log("requestFullScreen");
      requestFullScreen();
    }
    this.update()
  }

}
export class OpenBtn extends ButtonMv{
  constructor(child){
    super("more");
    this.btn.innerHTML = this.useIcon("more");
    this.btn.onclick = this.handleClick.bind(this);

    this.child = child;
  }
  handleClick(e){
    this.child.classList.toggle("hidden");
  }
}

export class MuteBtn extends Button{
  constructor(player){
    super("mute");
    this.btn.onclick = this.handleClick.bind(this);
    this.player = player;
    this.updateIcon(this.player.defaultMuted);
    var isMute = getMute()
    this.player.muted = isMute;
    this.updateIcon(isMute);
  }
  handleClick(e){
    this.player.muted =!this.player.muted;
    this.updateIcon(this.player.muted);
  }
  updateIcon(muted){
    if(muted){
      this.btn.innerHTML = this.useIcon("mute");
    }else{
      this.btn.innerHTML = this.useIcon("volume");
    }
  }
}

export class HomeBtn extends Button{
  constructor(child){
    super("home");
    this.btn.innerHTML = `<a href="/${this.scopeLink}">${this.useIcon("home")}</a>`;
    this.btn.onclick = this.handleClick.bind(this);

    this.child = child;
  }
  get scopeLink(){
    return window.clientScopeLink;
  }
  handleClick(e){
    console.log(this.child);
    this.child.classList.toggle("hidden");
  }
}


export class ShareBtn extends Button{
  constructor(player){
    super()
    this.btn = document.createElement("A");
    this.btn.className="control";
    this.btn.href=`` //can't find any usefull share intent.
    this.btn.innerHTML = this.useIcon("share");
  }
}
