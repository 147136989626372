'use strict';
import {getDownloadURL} from "./queries";
//default to si=true
export function humanReadableSize(bytes, si=true){
  var thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = si
    ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
    : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

export function getDownloadUrls(project){
  return Promise.all(Object.keys(project).reduce(function(promises, key){
    if (project[key] && /^gs:\/\//.test(project[key])){
      promises.push(
        getDownloadURL(project[key])
        .then((uri) =>{return { key, uri }})
      );
    }
    return promises;
  }, []))
  .then(uris => uris.reduce((o, r)=>{
    o[r.key]= r.uri;
    return o;
  },Object.assign({},project)));
}
