'use strict';
import {span} from "../utils/makeElement";

/* takes an initial element parameter
 * create and return a function with the following signature :
 * loaderCanProceed(html_as_string) : returns a "click" event promise
*/
export default function initialLoader(el){
  return function loaderCanProceed(icon){
    console.log("can proceed");
    icon = icon || iconDefault;
    el.innerHTML = icon;
    return new Promise(function(resolve, reject) {
      el.addEventListener("click",resolve);
    });
  }
}

const iconDefault = `<svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" height="48" viewBox="0 0 24 24" width="48">
  <path d="M0 0h24v24H0z" fill="none"/>
  <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/>
</svg>`;
