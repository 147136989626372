'use strict';
import {empty} from "../utils/makeElement";
import {gaEvent} from "../events/analytics";

const formatsMap = {
  "mp4":{
    type:"video/mp4; codecs=\"avc1.4D401F\"",
    rank:0 //lower is better
  },
  "webm":{
    type:"video/webm",
    rank:1,
  }
}

/**
 * Takes the target element and a playlist item as arguments.
 * Throw an error on failure
 */
export default function play(ve, item){
  const target_format = item.format || "pixel";
  if(!item.state == "DONE"){
    throw new Error("Invalid item : "+ item.id );
  }
  console.log("Playing : ",item);
  const source = document.createElement("SOURCE");
  const infos = formatsMap["mp4"];
  source.type = infos.type;
  source.codec = infos.codec;
  source.src = item.uri;
  source.onerror = function(e){
    console.warn.apply(this,arguments);
  };
  empty(ve);
  ve.appendChild(source);
  //Always re-trigger load
  ve.load();
  if(ve.paused){
    let res = ve.play();
    if (typeof res == "object" && typeof res.catch == "function"){
      res.catch((e)=>{
        console.warn("play error :", e);
      });
    }
  }
  gaEvent("Video", "play", `${item.scope}/${item.id}`);
}

//Find best format in project
// return null if not found
export function findBestFormat(item){
  return (item.uri)? {uri:item.uri, state:item.state, name: item.format}:null;
}
