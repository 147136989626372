'use strict';
import lockScreen from "./lockScreen";
//This is to be able to test outside of browser context
const w = (typeof window == "undefined")?{document:{}}:window;
var doc = w.document;
var docEl = w.document.documentElement;

export function isFullscreen(){
  var element = doc.fullscreenElement ||doc.mozFullScreenElement  ||doc.webkitFullscreenElement || doc.msFullscreenElement
  return (element)?true:false;
};

// there is no cancelVideoFullscreen as safari does it's own interface on fullscreen video.
function requestVideoFullscreen(){
  const videoEl = doc.getElementsByTagName("VIDEO")[0];
  if(videoEl && videoEl.webkitSupportsFullscreen){
    videoEl.webkitEnterFullscreen();
  }
}

export function requestFullScreen(){
  //console.log("request fullscreen");
  var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen|| requestVideoFullscreen; //Last fallback is for iOS safari which doesn't support fullscreen requests at all.
  requestFullScreen.call(docEl);

  lockScreen();
}

export function cancelFullScreen(){
  //console.log("cancel fullscreen")
  var doc = window.document;
  var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  cancelFullScreen.call(doc);
}
