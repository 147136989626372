'use strict';
/* detect if localStorage is available */
var storage;
try{
  storage = window.localStorage;
}catch(e){
  storage = {
    setItem:function(key,value){
      this["_"+key]= value;
    },
    removeItem: function(key){
      delete this["_"+key];
    },
    getItem: function(key){
      return this["_"+key];
    }
  }
}

export function setMute(state){
  storage.setItem("player_mute",state);
}
export function getMute(){
  return storage.getItem("player_mute");
}
