
export default function lockScreen(){
  /* Lock screen orientation when possible */
  var lockOrientationUniversal = screen.lockOrientation || screen.mozLockOrientation || screen.msLockOrientation;
  var orientationUniversal = screen.orientation || screen.mozOrientation || screen.msOrientation ||{type:"default"};
  if (typeof lockOrientationUniversal === "function"){
    lockOrientationUniversal.call(screen,orientationUniversal.type);
  }else if(screen.orientation && typeof screen.orientation.lock === "function"){
    screen.orientation.lock(orientationUniversal.type).catch(function(e){
      console.log(e);
    })
  }
}
