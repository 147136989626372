'use strict';

import Center from "./center";

export default class Video{
  constructor(player){
    this.player = player;
    this.center = new Center();
  }
  get ratio (){
    if(this.player.videoHeight != 0){
      return this.player.videoWidth/this.player.videoHeight;
    }else{
      return 4/3;
    }
  }
  resize(width,height){
    var style;
    if (width < height){
      style = this.portrait(width,height);
    }else{
      //console.log("Landscape mode")
      style = this.landscape(width,height);
    }
    Object.assign(this.player.style, style);
    //console.log("Assign style :",style);
  }
  landscape(width,height){
    //Landscape mode
    return this.makeStyleObject(
      height,
      (width -(height*this.ratio))/2,
      `${width/2}px ${width/2}px`,
      null
    );
  }
  portrait(width,height){
    return this.makeStyleObject(
      width,
      (height - (width*this.ratio))/2,
      `${width/2}px ${width/2}px`,
      "rotate(89.5deg)"
    );
  }
  //wrapper to provide Webkit prefixes along with "standard" style properties
  makeStyleObject(height, offset, origin, transform){
    //debug output expected values :
    //console.log("Video styles : ",height,offset);
    return {
      height: height+"px",
      //width is generally unneeded because video will shrink to content. But it's necessary if we want to pre-center video poster.
      width: (height*this.ratio)+"px",
      paddingLeft: offset+"px",
      transformOrigin: origin,
      WebkitTransformOrigin : origin,
      transform : transform,
      WebkitTransform : transform
    }
  }
}
