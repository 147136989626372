'use strict';

import {div} from "../utils/makeElement";

export function pixelSpinner({hidden}){
  const pad = "48px";
  return div({
    className: `pixel-spinner${(hidden?" hidden":"")}`
  }, [
    div({
      className:"spinner",
      style: {position:"absolute", left: "50%", top: pad}
    }),
    div({
      className:"spinner",
      style: {position:"absolute", top: "50%", right: pad}
    }),
    div({
      className:"spinner",
      style: {position:"absolute", bottom: pad, left: "50%"}
    }),
    div({
      className:"spinner",
      style: {position:"absolute", top: "50%", left: pad}
    }),
  ])
}

export function spinner({hidden, style, absolute}){
  const loader = div({
    className: `spinner${(hidden?" hidden":"")}`,
    style: style
  });
  return (absolute? div({style:{position: "absolute", top:0, left:0, right: 0, bottom: 0, paddingTop:'20%', background:"rgba(0,0,0,0.3)"}}, loader): loader);
}
